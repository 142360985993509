<template>
  <div style="font-weight: 200;">
    <div class="header">
      <img class="bg" src="/img/otherBg.png" alt="" />
      <div class="content">
        <div>
            <div class="title">公司介绍</div>
            <div class="desc">用科技创造未来 /<br />循环和再生，让地球更年轻</div>
        </div>  
      </div>
    </div>

    <div class="first">
        <div>
            <div class="title">我们的愿景</div>
            <div class="desc">蔚澜致力于描绘“碧海蓝天”的未来画卷，始终为环保事业、城市的可持续发展助力。</div>
        </div>
    </div>

    <div class="second">
        <div class="box">
            公司紧跟国际环保战略步伐，着眼全国环保城市建设方针，利用“互联网+”和“物联网”的技术平台，为客户提供高质量、高科技的环保产品，高效率、智能化的环保方案，现已拥有多项关于环保科技的自主知识产权。公司始终秉承品质、服务、创新、责任的发展宗旨，专业专注，缔造完美作为企业的核心价值观，不断引领公司向更高更远的目标持续迈进！
        </div>
        <div class="img">
           <img src="/img/VCG41N1196161943.png" alt="">
        </div>
    </div>

    <el-row class="third">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
            <div class="box">
                <img src="/img/about-footer1.png" alt="">
                <div class="content">
                    <div class="title">
                        我们的办公区
                    </div>
                    <div class="desc">
                        我们的办公区分为接待区、开放式办公区、各领导办公室、茶水间、会议室等功能区域，公司办公环境优美，设施完善，室内整洁明亮。
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="box">
                <img src="/img/IMG_508E28D50C68-1.png" alt="">
                <div class="content">
                    <div class="title">
                        我们的展区
                    </div>
                    <div class="desc">
                        展区陈列了可回收智能投递箱（柜）、智能单体投瓶机、智能过磅机等产品，还有综合数据墙展示可视化用户数据，欢迎来参观！
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="box">
                <img src="/img/about-footer3.png" alt="">
                <div class="content">
                    <div class="title">
                        我们的园区
                    </div>
                    <div class="desc">
                        园区是我们研发、组装、测试产品的基地。
                    </div>
                </div>
            </div>
        </el-col>
    </el-row> 

  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "./header.scss";
.first{
    background: url(/img/about-bg.png) left bottom/100% auto no-repeat;
    &>div{
        width: 80%;
        margin: 0 auto;
        max-width: 120rem;
        padding: 15rem 0 20rem;
        .title{
            font-size: 3rem;
            line-height: 3.5rem;
            letter-spacing: 1px;
            color: #288EFF;
            margin-bottom: 3rem;
        }
        .desc{
            font-size: 5rem;
            line-height: 8rem;
            letter-spacing: 1px;
            color: #252525;
        }
    }
}

.second{
    width: 100%;
    height: 42vw;
    position: relative;
    .img{
        width: 80%;
        max-width: 1400px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        img{
            width: 100%;
            display: block;
        }
    }
    .box{
       position: absolute;
       right: 0;
       top: 0;
       width: 80%;
       padding: 10rem;
       box-sizing: border-box;
       max-width: 96rem;
       //    margin-bottom: 33rem;
       background-color:#0670D8;
       font-size: 2.2rem;
       line-height: 5rem;
       letter-spacing: 1px;
       color: #FFFFFF;
       margin-top: -5vw;
       z-index: 2;
    }
}

.third{
   padding: 0 20px;
   .box{
       width: 80%;
       margin: 7rem auto;
       position: relative;
       img{
           width: 100%;
       }
       .content{
           position: absolute;
           bottom: 0;
           left: 0;
           width: 100%;
           height: 70%;
           background: linear-gradient(180deg, rgba(0, 9, 34, 0.0001) -2.08%, rgba(0, 9, 34, 0.510883) 35.69%, #000922 100%);
           box-sizing: border-box;
           padding: 30% 5.8rem;
           .title{
                font-size: 4rem;
                line-height: 4.7rem;
                letter-spacing: 1px;
                color: #FFFFFF;
           }
           .desc{
                font-size: 2rem;
                line-height: 4rem;
                letter-spacing: 1px;
                color: #FFFFFF;
                margin-top: 3.6rem;
           }
       }
   }
}


@media screen and (max-width: 1600px) {
   .second{
      height: 45vw;
   }
}

@media screen and (max-width: 1200px) {
   .second{
      height: 50vw;
   }
}

@media screen and (max-width: 992px) {
  .second{
      height: 53vw;
   }
}

@media screen and (max-width: 768px) {
  .second{
      height: auto;
      .box{
          width: 100vw;
          min-width: 100vw;
          position: relative;
          margin-top: 0;
      }
      .img{
          position: relative;
          top: -20px;
          width: 90vw;
          height: 90vw;
          overflow: hidden;
          z-index: 3;
          img{
              min-width: 100%;
              min-width: 100%;
              width: auto;
              height: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
          }
      }
   }
}
</style>
